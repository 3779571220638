<template>
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>Login</h1>
        </div>
      </div>
      <div class="row">
        <div class="col box-wrapper">
          <div class="alert alert-danger" role="alert" v-if="error" v-html="error"></div>
          <form v-if="user !== false">
            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
              <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
            </div>
            <button type="button" class="btn btn-primary" @click="login">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'login',
  components: {
  },
  data() {
    return {
      user: false,
      email: '',
      password: '',
      error: null
    }
  },
  mounted() {
    const self = this;
    firebase.auth().onAuthStateChanged((user) => {
      self.user = user;
      if (user && !user.isAnonymous) {
        self.$router.push({name: 'home'});
      }
    });
  },
  methods: {
    login() {
      debugger;
      const self = this;
      const credential = firebase.auth.EmailAuthProvider.credential(this.email, this.password);

      /*
      if (this.user && this.user.isAnonymous) {
        this.user.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
          self.$router.push({name: 'home'});
        }, function(error) {
          this.error = '<strong>Login error</strong>';
        });
      }
      */
      
      if (!this.user || this.user.isAnonymous) {
        firebase.auth().signInWithCredential(credential)
          .then((user) => {
            self.$router.push({name: 'home'});
          })
          .catch((error) => {
            this.error = '<strong>Login error</strong><br>' + error.message;
          });
      }else {
        self.$router.push({name: 'home'});
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">

</style>
